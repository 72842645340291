import React from 'react';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  Link,
  PageNavigation,
  Paragraph,
  PlatformTab,
  Section,
} from '../../../../components';

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Weights', 'Sizes']} />

      <Section title="Weights">
        <Paragraph>Lighten or bold any enclosed text.</Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Light</DataTableValueBlock>
              <DataTableValueBlock>300</DataTableValueBlock>
              <DataTableCodeBlock>var(--font-weight-light)</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Default</DataTableValueBlock>
              <DataTableValueBlock>400</DataTableValueBlock>
              <DataTableCodeBlock>
                var(--font-weight-default)
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Bold</DataTableValueBlock>
              <DataTableValueBlock>bold</DataTableValueBlock>
              <DataTableCodeBlock>var(--font-weight-bold)</DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>

      <Section title="Sizes">
        <Paragraph>
          Change the size of the enclosed text. Try to use the{' '}
          <Link href="/components/type/text" isDesignCodeLink>
            text components
          </Link>{' '}
          instead though.
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Micro</DataTableValueBlock>
              <DataTableValueBlock>0.75rem</DataTableValueBlock>
              <DataTableCodeBlock>var(--font-size-micro)</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Small</DataTableValueBlock>
              <DataTableValueBlock>0.875rem</DataTableValueBlock>
              <DataTableCodeBlock>var(--font-size-small)</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Default</DataTableValueBlock>
              <DataTableValueBlock>1rem</DataTableValueBlock>
              <DataTableCodeBlock>var(--font-size-default)</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Large</DataTableValueBlock>
              <DataTableValueBlock>1.125rem</DataTableValueBlock>
              <DataTableCodeBlock>var(--font-size-large)</DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
